import React from "react";
import { multipleClassNames } from "shared/utilities";

import styles from "./Container.module.scss";

const Container = ({ children, className = [] }) => {
  const style = [styles.container, ...className];
  return <div className={multipleClassNames(style)}>{children}</div>;
};

export default Container;
