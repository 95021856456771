import { nanoid } from "nanoid";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";

import { addNotifications } from "../slices";
import { auth, firestoreDB } from "shared/config";
import { ENDPOINTS, NOTIFICATION_TYPE, USER_ROLES } from "shared/constants";

export const signInUser = createAsyncThunk(
  "user/signInUser",
  async ({ email, password }) =>
    await signInWithEmailAndPassword(auth, email, password).then((res) => {
      const { _tokenResponse } = res;
      return _tokenResponse;
    })
);

export const signOutUser = createAsyncThunk("user/signOutUser", async () => {
  await auth.signOut();
  window.location.href = window.location.origin;
  localStorage.clear();
});

export const getUserData = createAsyncThunk("user/getUserData", async (payload, { dispatch }) => {
  const uid = auth?.currentUser?.uid;

  const timeNow = new Date().getTime();
  if (!uid) return null;

  const q = query(collection(firestoreDB, ENDPOINTS.users), where("uid", "==", uid));

  const d = await getDocs(q);

  const [u] = d.docs.map((doc) => doc.data());

  if (u?.membership_expiration_date < timeNow) {
    dispatch(addNotifications({ id: nanoid(), text: "Your membership expired. Please contact your administrator.", type: NOTIFICATION_TYPE.error }));
    dispatch(signOutUser());
  }

  return u;
});

export const updateUserData = createAsyncThunk("users/updateUserData", async (payload, { dispatch }) => {
  try {
    await setDoc(doc(firestoreDB, ENDPOINTS.users, payload?.uid), payload, { merge: true });
    dispatch(addNotifications({ id: nanoid(), text: `${payload?.email}'s account updated successfully`, type: NOTIFICATION_TYPE.success }));
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(
      addNotifications({ id: nanoid(), text: `Could not update account for ${payload?.email}. Check with your administrator`, type: NOTIFICATION_TYPE.error })
    );
    return null;
  }
});

export const userReducers = (builder) => {
  builder.addCase(signInUser.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(signInUser.fulfilled, (state) => {
    state.isLoading = false;
  });
  builder.addCase(signInUser.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(signOutUser.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(signOutUser.fulfilled, (state) => {
    state.isLoading = false;
    state.data = null;
    state.isAdmin = false;
  });
  builder.addCase(signOutUser.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(getUserData.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getUserData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.data = payload;
    state.isAdmin = payload?.roles?.includes(USER_ROLES.admin);
  });
  builder.addCase(getUserData.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(updateUserData.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(updateUserData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.data = { ...state.data, ...payload };
  });
  builder.addCase(updateUserData.rejected, (state) => {
    state.isLoading = false;
  });
};
