import { createSlice } from "@reduxjs/toolkit";
import { seasonsReducers } from "../reducers";

const initialState = {
  isLoading: false,
  list: [],
  lastUpdate: null,
};

const reducers = {
  setSeasonsLastUpdate(state, { payload }) {
    state.lastUpdate = payload;
  },
};

const seasonsSlice = createSlice({
  name: "seasons",
  initialState,
  reducers,
  extraReducers: seasonsReducers,
});

export const { setSeasonsLastUpdate } = seasonsSlice.actions;
export const seasons = seasonsSlice.reducer;
