export function passCheck(data, setNewPassword, setFormTouched, setIsPasswordValid) {
  setNewPassword(data);
  const passClass = document.getElementsByClassName("password-check");
  const tickClass = document.getElementsByClassName("titick");
  const crossClass = document.getElementsByClassName("cross");
  const lowerCase = new RegExp("(?=.*[a-z])");
  const upperCase = new RegExp("(?=.*[A-Z])");
  const specialChar = new RegExp("(?=.*[!@#$%^&*])");
  const number = new RegExp("(?=.*[0-9])");
  const eightChar = new RegExp("(?=.{6,})");

  if (upperCase.test(data)) {
    passClass[0].style.color = "green";
    tickClass[0].style.display = "block";
    crossClass[0].style.display = "none";
  } else {
    passClass[0].style.color = "white";
    tickClass[0].style.display = "none";
    crossClass[0].style.display = "block";
  }
  if (lowerCase.test(data)) {
    passClass[1].style.color = "green";
    tickClass[1].style.display = "block";
    crossClass[1].style.display = "none";
  } else {
    passClass[1].style.color = "white";
    tickClass[1].style.display = "none";
    crossClass[1].style.display = "block";
  }
  if (specialChar.test(data)) {
    passClass[2].style.color = "green";
    tickClass[2].style.display = "block";
    crossClass[2].style.display = "none";
  } else {
    passClass[2].style.color = "white";
    tickClass[2].style.display = "none";
    crossClass[2].style.display = "block";
  }
  if (number.test(data)) {
    passClass[3].style.color = "green";
    tickClass[3].style.display = "block";
    crossClass[3].style.display = "none";
  } else {
    passClass[3].style.color = "white";
    tickClass[3].style.display = "none";
    crossClass[3].style.display = "block";
  }
  if (eightChar.test(data)) {
    passClass[4].style.color = "green";
    tickClass[4].style.display = "block";
    crossClass[4].style.display = "none";
  } else {
    passClass[4].style.color = "white";
    tickClass[4].style.display = "none";
    crossClass[4].style.display = "block";
  }
  setFormTouched(true);
  setIsPasswordValid(lowerCase.test(data) && upperCase.test(data) && specialChar.test(data) && number.test(data) && eightChar.test(data));
}
