import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaEnvelope, FaExternalLinkAlt, FaIdBadge, FaMapPin, FaPhone, FaSuitcase } from "react-icons/fa";
import { GiSoccerBall } from "react-icons/gi";

import { ROUTES } from "shared/constants";
import { getDisplayName, getFormatedDate } from "shared/utilities";
import { BOX_COLORS, Box, IconLabel } from "components/library";

import styles from "./ContentSidebar.module.scss";

export const ContentSidebar = () => {
  const userData = useSelector((store) => store.user.data);
  const displayName = getDisplayName(userData);

  return (
    <div className={styles.sidebar}>
      <div className={styles.profile}>
        <img className={styles.profile__cover} src="/img/cover.jpg" alt="profile cover" />
        <img className={styles.profile__image} src="/img/user_default.jpg" alt="profile banner" />
        <div className={styles.profile__about}>
          <Link to={ROUTES.dashboard}>{displayName}</Link>
          <span>{userData?.job_title}</span>
        </div>
      </div>

      <Box color={BOX_COLORS.light} className={[styles.aboutMe]}>
        <IconLabel title="Club" text={userData?.club || "-"}>
          <GiSoccerBall />
        </IconLabel>
        <IconLabel title="Membership" text={getFormatedDate(userData?.membership_expiration_date)}>
          <FaIdBadge />
        </IconLabel>
        <IconLabel title="Location" text={userData?.location || "-"}>
          <FaMapPin />
        </IconLabel>
        <IconLabel title="Job title" text={userData?.job_title || "-"}>
          <FaSuitcase />
        </IconLabel>
        <IconLabel title="Email" text={userData?.email || "-"}>
          <FaEnvelope />
        </IconLabel>
        <IconLabel title="Phone number" text={userData?.phone_number || "-"}>
          <FaPhone />
        </IconLabel>
        <IconLabel title="Website" text={userData?.website || "-"}>
          <FaExternalLinkAlt />
        </IconLabel>
      </Box>

      <Link to={`${ROUTES.organizations}/${userData?.organization}`} className={styles.organization}>
        {/* <img className={styles.organization__cover} src="/img/cover.jpg" alt="organization cover" /> */}
        <img className={styles.organization__image} src={`/organizations/fsoa.png`} alt="organization logo" />
      </Link>
    </div>
  );
};
