export const BASE_URL = `${window.location.origin}/archive`;

export const ENDPOINTS = {
  users: "users",
  reports: "reports",
  seasons: "seasons",
  organizations: "organizations",
  tweets: "tweets",
  data: "data",
  reporting: "reporting",
};

export const SUB_ENDPOINTS = {
  agencies: "agencies",
  dog_agencies: "dog_agencies",
  clubs: "clubs",
  venues: "venues",
  tweets: "tweets",
};
