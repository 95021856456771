import React from "react";
import { multipleClassNames } from "shared/utilities";

import styles from "./Button.module.scss";

export const Button = ({ children, className = [], color = "default", type = "button", ...props }) => {
  const style = [styles.button, ...className];

  if (color) {
    style.push(styles[color]);
  }

  return (
    <button type={type} {...props} className={multipleClassNames(style)}>
      {children}
    </button>
  );
};
