import React from "react";
import { multipleClassNames } from "shared/utilities";

import styles from "./Box.module.scss";

export const Box = ({ className = [], color = "default", children }) => {
  const style = [styles.box, ...className];

  if (color) {
    style.push(styles[color]);
  }

  return <div className={multipleClassNames(style)}>{children}</div>;
};
