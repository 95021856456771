import { nanoid } from "nanoid";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDoc, collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";

import { firestoreDB } from "shared/config";
import { addNotifications } from "../slices";
import { ENDPOINTS, NOTIFICATION_TYPE } from "shared/constants";
import { sortStringsList, updateSeasonCompetition } from "shared/utilities";

export const getSeasons = createAsyncThunk("main/getSeasons", async () => {
  const q = collection(firestoreDB, ENDPOINTS.seasons);
  const d = await getDocs(q);
  return d.docs.map((doc) => ({ id: doc?.id, ...doc.data() }));
});

export const addSeasonToDatabase = createAsyncThunk("main/addSeasonToDatabase", async (payload, { dispatch }) => {
  try {
    const d = await addDoc(collection(firestoreDB, ENDPOINTS.seasons), payload);
    dispatch(addNotifications({ id: nanoid(), text: `${payload?.name}'s added successfully`, type: NOTIFICATION_TYPE.success }));
    return { id: d?.id, ...payload };
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Could not add ${payload?.name}. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const deleteSeasonFromDatabase = createAsyncThunk("main/deleteSeasonFromDatabase", async (payload, { dispatch }) => {
  try {
    deleteDoc(doc(firestoreDB, ENDPOINTS.seasons, payload?.id));
    dispatch(addNotifications({ id: nanoid(), text: `${payload?.name}'s deleted successfully`, type: NOTIFICATION_TYPE.success }));
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Could not delete ${payload?.name}. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const addCompetitionToSeason = createAsyncThunk("main/addCompetitionToSeason", async (payload, { getState, dispatch }) => {
  try {
    const store = await getState();
    const state = store.seasons.list;
    const { uid, ...payloadData } = payload;

    const seasonData = state.find((season) => season?.id === uid);
    const isCompetitionInSeason = seasonData && seasonData?.competitions?.find((item) => item?.id === payloadData?.id);
    let newSeasonData = {};

    if (isCompetitionInSeason) {
      const list = seasonData?.competitions?.map((item) => (item?.id === payloadData?.id ? payloadData : item));
      newSeasonData = { ...seasonData, competitions: list };
    } else {
      newSeasonData = { ...seasonData, competitions: [...seasonData?.competitions, payloadData] };
    }

    await setDoc(doc(firestoreDB, ENDPOINTS.seasons, uid), newSeasonData, { merge: true });
    dispatch(addNotifications({ id: nanoid(), text: `${payload?.name}'s added successfully`, type: NOTIFICATION_TYPE.success }));
    return newSeasonData;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Could not add ${payload?.name}. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
  }
});

export const removeCompetitionFromSeason = createAsyncThunk("main/removeCompetitionFromSeason", async (payload, { getState, dispatch }) => {
  try {
    const store = await getState();
    const state = store.seasons.list;
    const { uid, ...payloadData } = payload;

    const seasonData = state.find((season) => season?.id === uid);
    const filterCompetition = seasonData?.competitions?.filter((competition) => competition?.id !== payloadData?.id);
    const newSeasonData = { ...seasonData, competitions: filterCompetition };

    await setDoc(doc(firestoreDB, ENDPOINTS.seasons, uid), newSeasonData, { merge: true });
    dispatch(addNotifications({ id: nanoid(), text: `${payload?.name}'s deleted successfully`, type: NOTIFICATION_TYPE.success }));
    return newSeasonData;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Could not delete ${payload?.name}. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
  }
});

export const seasonsReducers = (builder) => {
  builder.addCase(getSeasons.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getSeasons.fulfilled, (state, { payload }) => {
    state.list = sortStringsList(payload)?.reverse();
    state.isLoading = false;
  });
  builder.addCase(getSeasons.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(addSeasonToDatabase.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(addSeasonToDatabase.fulfilled, (state, { payload }) => {
    state.list = sortStringsList([...state.list, payload]).reverse();
    state.isLoading = false;
  });
  builder.addCase(addSeasonToDatabase.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(deleteSeasonFromDatabase.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(deleteSeasonFromDatabase.fulfilled, (state, { payload }) => {
    const list = state.list.filter((item) => item?.id !== payload?.id);
    state.list = sortStringsList(list).reverse();
    state.isLoading = false;
  });
  builder.addCase(deleteSeasonFromDatabase.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(addCompetitionToSeason.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(addCompetitionToSeason.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.list = updateSeasonCompetition(payload, state.list);
  });
  builder.addCase(addCompetitionToSeason.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(removeCompetitionFromSeason.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(removeCompetitionFromSeason.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.list = updateSeasonCompetition(payload, state.list);
  });
  builder.addCase(removeCompetitionFromSeason.rejected, (state) => {
    state.isLoading = false;
  });
};
