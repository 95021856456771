import React from "react";
import { multipleClassNames } from "shared/utilities";

import styles from "./ReactForm.module.scss";

export const ReactForm = ({ children, className = [], onSubmit, ...props }) => {
  return (
    <form className={multipleClassNames([styles.form, ...className])} onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};
