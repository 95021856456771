import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";

import { firestoreDB } from "shared/config";
import { ENDPOINTS } from "shared/constants";

export const getOwnershipRequests = createAsyncThunk("ownership/getOwnershipRequests", async () => {
  const q = collection(firestoreDB, ENDPOINTS.ownership);
  const d = await getDocs(q);
  return d.docs.map((doc) => ({ id: doc?.id, ...doc.data() }));
});

export const ownershipReducers = (builder) => {
  builder.addCase(getOwnershipRequests.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getOwnershipRequests.fulfilled, (state, { payload }) => {
    state.list = payload;
    state.isLoading = false;
  });
  builder.addCase(getOwnershipRequests.rejected, (state) => {
    state.isLoading = false;
  });
};
