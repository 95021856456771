import React from "react";
import { Label } from "components/typography";
import { multipleClassNames } from "shared/utilities";
import styles from "./TextArea.module.scss";

export const TextArea = ({ className = [], formik, label, rows = 4, name, disabled, ...props }) => {
  return (
    <Label label={label} disabled={disabled} className={multipleClassNames([...className])}>
      <textarea
        name={name}
        rows={rows}
        as="textarea"
        className={styles.textArea}
        onChange={formik.handleChange}
        value={formik.values[name]}
        disabled={disabled}
        maxLength={2000}
        {...props}
      />
    </Label>
  );
};
