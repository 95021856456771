import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";

import { auth } from "shared/config";
import LoadingPage from "pages/loading";
import Container from "containers/Container";
import { key } from "containers/AppStore/AppStore";
import WithPermissions from "containers/WithPermissions";
import { clearLocalStorageFromOldKeys } from "shared/utilities";
import { setAccountDropdown } from "containers/AppStore/slices";
import { Header, Footer, NotificationCard } from "./components";
import { EXT_ROUTES, INT_ROUTES, ROUTES } from "shared/constants";

import styles from "./Layout.module.scss";

const Layout = ({ children, contentSidebar, setOptionVisible, optionVisible }) => {
  const dispach = useDispatch();

  // User Auth
  const [user, loading, error] = useAuthState(auth);
  const uid = user?.uid;

  // User Location
  const location = useLocation();
  const navigate = useNavigate();

  const accountDropdownActive = useSelector((store) => store.main.accountDropdown);
  const isEXT = Object.values(EXT_ROUTES).includes(location.pathname);

  const handleAutoClose = (e) => {
    if (accountDropdownActive) {
      if (e?.target?.getAttribute("data-target") !== "menu") {
        dispach(setAccountDropdown());
      }
    }
  };

  const handleLayoutClick = (e) => {
    e.stopPropagation();
    if (setOptionVisible) {
      setOptionVisible(false);
    }
  };

  React.useEffect(() => {
    clearLocalStorageFromOldKeys(key);
  });

  switch (true) {
    case loading:
      return <LoadingPage />;
    case error:
      navigate(ROUTES.login);
      return <></>;
    case !isEXT && !uid:
      navigate(ROUTES.login);
      return <></>;
    case isEXT && uid:
      navigate(INT_ROUTES.dashboard);
      return <></>;
    default:
      return (
        <div onClick={handleLayoutClick}>
          <NotificationCard />
          <div className={styles.layout} onClick={(e) => handleAutoClose(e)}>
            {uid && <Header />}
            <WithPermissions>
              <main className={styles.layout}>
                <Container className={[contentSidebar ? styles.layout__container : styles.layout__main]}>
                  {contentSidebar}
                  <div className={styles.layout__grid}>{children}</div>
                </Container>
              </main>
            </WithPermissions>
            <Footer />
          </div>
        </div>
      );
  }
};

export default Layout;
