export const USER_ROLES = {
  admin: "admin",
  full_membership: "full_membership",
  associate: "associate",
};

export const USER_ROLES_LABELS = {
  admin: "Administrator",
  full_membership: "Full Membership",
  associate: "Associate Membership",
};

export const ORGANIZATIONS = {
  fsoa: "fsoa",
  aposto: "aposto",
};

export const ORGANIZATIONS_LABELS = {
  fsoa: "FSOA",
  aposto: "Aposto",
};

export const getMembershipTypeOptions = () => {
  const options = Object.values(USER_ROLES)?.map((n) => ({ label: USER_ROLES_LABELS[n], value: n }));
  return options;
};

export const ORGANIZATIONS_LIST = [
  {
    label: ORGANIZATIONS_LABELS.fsoa,
    value: ORGANIZATIONS.fsoa,
  },
  {
    label: ORGANIZATIONS_LABELS.aposto,
    value: ORGANIZATIONS.aposto,
  },
];

export const REGION_TYPES = ["North West", "North East", "West Midlands", "Northern Ireland", "East Midlands", "South West", "South East"];
