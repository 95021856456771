import React from "react";
import styles from "./Label.module.scss";
import { multipleClassNames } from "shared/utilities";

export const Label = ({ className = [], children, label, disabled, ...props }) => {
  if (!label) return children;
  const style = [styles.formLabel, ...className];

  if (disabled) {
    style.push(styles.formLabel__disabled);
  }

  return (
    <label className={multipleClassNames(style)} {...props}>
      <span>{label}</span>
      {children}
    </label>
  );
};
