import React from "react";
import styles from "./IconLabel.module.scss";

export const IconLabel = ({ children, title = "", text = "" }) => {
  return (
    <div className={styles.iconLabel}>
      <div className={styles.iconLabel__icon}>{children}</div>
      <div className={styles.iconLabel__title}>
        <span>{title}</span>
        <span>{text}</span>
      </div>
    </div>
  );
};
