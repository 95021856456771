import React from "react";
import { Title } from "components/typography";
import styles from "styles/pages/Loading.module.scss";

export default function LoadingPage() {
  return (
    <div className={styles.loading}>
      <img src="/img/logo_icon.png" alt="loading icon" title="reporting tool loading" />
      <Title>Loading...</Title>
    </div>
  );
}
