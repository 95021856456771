import React from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

import { multipleClassNames } from "shared/utilities";
import { Button, BUTTON_COLORS } from "components/library";

import styles from "./ReactModal.module.scss";

export const ReactModal = ({ className = [], isActive = false, onClick, children, ...props }) => {
  const closeModal = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  };

  return (
    <Modal className={multipleClassNames([styles.reactModal, ...className])} isOpen={isActive} onRequestClose={closeModal} ariaHideApp={false} {...props}>
      <div className={styles.reactModal__body}>
        <div className={styles.reactModal__close}>
          <Button color={BUTTON_COLORS.link} onClick={closeModal}>
            <FaTimes size={24} />
          </Button>
        </div>
        <div className={styles.reactModal__content}>{children}</div>
      </div>
    </Modal>
  );
};
