import { nanoid } from "nanoid";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDoc, collection, deleteDoc, doc, getDocs } from "firebase/firestore";

import { firestoreDB } from "shared/config";
import { addNotifications } from "../slices";
import { ENDPOINTS, NOTIFICATION_TYPE } from "shared/constants";

export const getTweets = createAsyncThunk("tweets/getTweets", async (payload, { dispatch }) => {
  try {
    const d = await getDocs(collection(firestoreDB, ENDPOINTS.tweets));
    const r = d.docs.map((doc) => ({ id: doc?.id, ...doc.data() }));
    return r;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Could not retrieve tweets. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const addTweet = createAsyncThunk("tweets/addTweet", async (payload, { dispatch }) => {
  try {
    const d = await addDoc(collection(firestoreDB, ENDPOINTS.tweets), payload);
    dispatch(addNotifications({ id: nanoid(), text: "Tweet posted successfully.", type: NOTIFICATION_TYPE.success }));
    return { id: d?.id, ...payload };
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Could not add tweets. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const removeTweet = createAsyncThunk("tweets/removeTweet", async (payload, { dispatch }) => {
  try {
    const { id } = payload;
    await deleteDoc(doc(firestoreDB, ENDPOINTS.tweets, id));
    dispatch(addNotifications({ id: nanoid(), text: "Tweet deleted successfully.", type: NOTIFICATION_TYPE.error }));
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Could not delete tweet. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const tweetsReducers = (builder) => {
  builder.addCase(getTweets.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getTweets.fulfilled, (state, { payload }) => {
    state.list = payload;
    state.isLoading = false;
  });
  builder.addCase(getTweets.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(addTweet.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(addTweet.fulfilled, (state, { payload }) => {
    state.list = [...state?.list, payload];
    state.isLoading = false;
  });
  builder.addCase(addTweet.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(removeTweet.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(removeTweet.fulfilled, (state, { payload }) => {
    state.list = state.list?.filter((item) => item?.id !== payload?.id);
    state.isLoading = false;
  });
  builder.addCase(removeTweet.rejected, (state) => {
    state.isLoading = false;
  });
};
