import { ROUTES } from "shared/constants";

export const NAVIGATION = Object.freeze([
  { name: "Home", route: ROUTES.dashboard },
  { name: "Reports", route: ROUTES.reports },
  { name: "Members", route: ROUTES.members },
  { name: "Settings", route: ROUTES.settings },
  { name: "Admin Panel", route: ROUTES.admin_panel },
]);

export const NAVIGATION_SMALL = Object.freeze([{ name: "Help", route: ROUTES.help }]);
