import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";

import { firestoreDB } from "shared/config";
import { ENDPOINTS } from "shared/constants";

export const getDuplicatesRequests = createAsyncThunk("duplicates/getDuplicatesRequests", async () => {
  const q = collection(firestoreDB, ENDPOINTS.duplicates);
  const d = await getDocs(q);
  return d.docs.map((doc) => ({ id: doc?.id, ...doc.data() }));
});

export const duplicatesReducers = (builder) => {
  builder.addCase(getDuplicatesRequests.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getDuplicatesRequests.fulfilled, (state, { payload }) => {
    state.list = payload;
    state.isLoading = false;
  });
  builder.addCase(getDuplicatesRequests.rejected, (state) => {
    state.isLoading = false;
  });
};
