import { createSlice } from "@reduxjs/toolkit";
import { duplicatesReducers } from "../reducers";

const initialState = {
  isLoading: false,
  list: [],
  lastUpdate: null,
};

const reducers = {
  setDuplicatesLastUpdate(state, { payload }) {
    state.lastUpdate = payload;
  },
};

const duplicatesSlice = createSlice({
  name: "duplicates",
  initialState,
  reducers,
  extraReducers: duplicatesReducers,
});

export const { setDuplicatesLastUpdate } = duplicatesSlice.actions;
export const duplicates = duplicatesSlice.reducer;
