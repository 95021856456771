import React from "react";
import styles from "./Footer.module.scss";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <div>
        Powered by{" "}
        <a href="https://aposto.co.uk/" target="_blank" rel="noreferrer">
          Aposto Ltd
        </a>{" "}
        &copy; {year}
      </div>
      <div>
        Contact:{" "}
        <a target="_blank" rel="noreferrer" href="mailto:info@fsoa.org.uk">
          info@fsoa.org.uk
        </a>
        {" / "}
        <a target="_blank" rel="noreferrer" href="tel:01615219987">
          0161 521 9987
        </a>
      </div>
    </footer>
  );
};
