import { createSlice } from "@reduxjs/toolkit";
import { dataReducers } from "../reducers";

const initialState = {
  isLoading: false,
  id: null,
  clubs: [],
  venues: [],
  agencies: [],
  dog_agencies: [],
  lastUpdate: null,
};

const reducers = {
  setDataLastUpdate(state, { payload }) {
    state.lastUpdate = payload;
  },
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers,
  extraReducers: dataReducers,
});

export const { setDataLastUpdate } = dataSlice.actions;
export const data = dataSlice.reducer;
